import React from "react"
import Box from "@mui/material/Box"
import { Link } from "gatsby"

function NotFund() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div>Not FOUND</div>
      <Box sx={{ mt: 5 }}>
        <Link to="/">back home</Link>
      </Box>
    </Box>
  )
}

export default NotFund
